import { select } from "d3-selection";
import { timer } from "d3-timer";
import { json } from "d3-fetch";
import { geoOrthographic, geoPath, geoGraticule, geoDistance } from "d3-geo";

export default {
	select,
	timer,
	json,
	geoOrthographic,
	geoPath,
	geoGraticule,
	geoDistance,
};
