import 'bootstrap/js/dist/collapse.js';

function init () {
	document.querySelectorAll('.site-menu__toggle').forEach(function(elm){
		elm.addEventListener('click', function(e){
			if(window.outerWidth <= 768) {
				e.preventDefault();
			}

			document.getElementById('site_header').classList.toggle('js-active');
		});
	});

	window.addEventListener('resize', function () {
		if(window.outerWidth > 768) {
			document.getElementById('site_header').classList.remove('js-active');
		}
	});

	$('.collapse').collapse();

	// Allows alternating flex-direction on block quotes
	$('.block-quote:eq(1), .block-quote:eq(3), .block-quote:eq(5), .block-quote:eq(7)').addClass('even');

	// Add smooth scroll to anchor links
	$(document).on('click', '.content-blocks a[href^="#"]', function (event) {
		event.preventDefault();

		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top - 20
		}, 500);
	});
}

const ui = {
	init
};

export { ui as default };
