/**
 * Attribution - Based on D3 Block by ...
 * @author       Atanu Mallick
 * @author       Joe Tercero
 * @website      https://bl.ocks.org/atanumallick/8d18989cd538c72ae1ead1c3b18d7b54
 **/

import d3 from "./d3";
import * as topojson from "topojson";
import worldData from "../assets/geojson/world-landmass.json";

const width = 900;
const height = 900;
const scale = 450;
const clipAngle = 180;
const config = {
  yawSpeed: -0.002,
  yaw: 0,
  rollSpeed: 0.0005,
  roll: 0,
  pitch: 20
};
let locations = [];
const svg = d3
  .select("#hero-globe")
  .attr("width", width)
  .attr("height", height)
  .attr("viewBox", "0 0 900 900");
const markerGroup = svg.append("g");
const projection = d3.geoOrthographic();
const initialScale = projection.scale(scale);
const path = d3.geoPath().projection(projection);
const center = [width / 2, height / 2];

/**
 * Draw the Globe Layer with an External JSON
 */
function drawExternalLocations() {
  const api_url = process.env.API_URL || "http://localhost:8000/graphql/";
  const locations_query =
    "query=query { viewer {siteMeta {openLocations" +
    " {geoLocation {lat,lng}}}}}";
  const locations_end_point = api_url + "?" + locations_query;
  d3.json(locations_end_point)
    .then(() => d3.json(locations_end_point))
    .then(locationData => {
      let {
        data: {
          viewer: {
            siteMeta: { openLocations }
          }
        } = { viewer: { siteMeta: { openLocations: {} } } }
      } = locationData;
      locations = openLocations
        .filter(location => !!location.geoLocation)
        .map(location => {
          const {
            geoLocation: { lat: latitude, lng: longitude }
          } = location;
          return { latitude, longitude };
        });
    })
    .catch(() => {});
}

/**
 * Draw the Globe Layer
 */
function drawGlobe() {
  svg
    .append("path")
    .datum({
      type: "FeatureCollection",
      features: [topojson.feature(worldData, worldData.objects.land)]
    })
    .attr("d", path)
    .style("stroke", "#888")
    .style("stroke-width", "1px")
    .style("fill", (d, i) => "#e5e5e5")
    .style("fill-opacity", "0")
    .style("opacity", "0.6");
}

/**
 * Draw the Graticule Layer
 */
function drawGraticule() {
  const graticule = d3.geoGraticule();

  svg
    .append("path")
    .datum(graticule)
    .attr("class", "graticule")
    .attr("d", path)
    .style("fill", "#fff")
    .style("fill-opacity", "0")
    .style("stroke", "#ccc");
}

/**
 * Enable the the Globe Rotation
 */
function enableRotation() {
  d3.timer(function(elapsed) {
    projection.rotate([
      config.yawSpeed * elapsed + config.yaw,
      config.rollSpeed * elapsed + config.roll,
      config.pitch
    ]);
    svg.selectAll("path").attr("d", path);
    drawMarkers();
  });
}

/**
 * Draw the Location Markers Layer
 */
function drawMarkers() {
  const markers = markerGroup.selectAll("circle").data(locations);
  markers
    .enter()
    .append("circle")
    .merge(markers)
    .attr("cx", d => projection([d.latitude, d.longitude])[0])
    .attr("cy", d => projection([d.latitude, d.longitude])[1])
    .attr("class", "dots")
    .attr("fill", d => {
      const coordinate = [d.latitude, d.longitude];
      const gdistance = d3.geoDistance(coordinate, projection.invert(center));
      return gdistance > 1.57 ? "none" : "#4BB987";
    })
    .attr("r", 4);

  markerGroup.each(function() {
    this.parentNode.appendChild(this);
  });
}

/**
 * Initiate the Draw
 */
function init() {
  drawGlobe();
  drawExternalLocations();
  drawGraticule();
  enableRotation();
}

const d3Globe = {
  init
};

export { d3Globe as default };
