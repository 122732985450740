import Glide from "@glidejs/glide";

function init(elm) {
  const slideshows = document.getElementsByClassName(elm);

  if (slideshows.length > 0) {
    const glide = new Glide("." + elm, {
      type: "carousel",
      perView: 4,
      focusAt: "center",
      autoplay: 3500,
      breakpoints: {
        800: {
          perView: 2
        },
        480: {
          perView: 1
        }
      }
    });

    glide.mount();
  }
}

const slideshow = {
  init
};

export { slideshow as default };
